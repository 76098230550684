import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

function LinkedInCompanyConnector({}) {
  const [contactSucces, setContactSucces] = useState(false);
  const [contactFail, setContactFail] = useState(false);

  const [apiKey, setApiKey] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: ``,
      keepInformed: true,
    },
    validate: async (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      return errors;
    },
    onSubmit: async (values) => {
      let { email, keepInformed } = values;

      let postBody = {
        email: email,
        keepInformed: String(keepInformed),
        connectorId: `linkedin_company_connector`,
      };

      try {
        let postData = await axios.post(
          `https://data-studio.gladior.com/generic`,
          postBody,
          {
            headers: {
              "x-api-key": `8oSbuqEn7o7ndd1E4xAow19mdcEnOTioSJ9hA2fa`,
            },
          }
        );

        setApiKey(postData.data.apiKey);

        try {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: `key_aanvragen`,
            actie_email: email,
            label_keepinformed: String(keepInformed),
          });
        } catch (e) {}

        setContactSucces(true);
      } catch (e) {
        setContactFail(true);
      }
    },
  });

  let connectorLink = `https://datastudio.google.com/u/0/datasources/create?connectorId=AKfycbzhDs5_k99z6nzLgRMWaKwALAGhePdzDx2d3OtazsY`;

  return (
    <>
      <SEO
        description={`LinkedIn Company Connector for Google Data Studio`}
        pathname={`/data-studio/linkedin-company-connector`}
        title={`LinkedIn Company Connector`}
      />
      <Layout
        subtitle={`Google Data Studio`}
        title={`LinkedIn Company Connector`}
      >
        <main className="bg-gladior-dark ">
          <section className="container mx-auto px-6 py-10  max-w-7xl ">
            <h3 className="text-xl text-white font-bold mb-3 font-display ">
              LinkedIn Company Connector for Google Data Studio
            </h3>

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              With the LinkedIn company Connector you can report on the most
              important statistics of your LinkedIn company page. To use this
              connector you need an API Key. You can request this through the
              form below.
            </p>

            <h3 className="mt-6 text-xl text-white font-bold mb-3 font-display ">
              Setting up the Google Data Studio integration
            </h3>

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              With this API key you can use the connector.
            </p>

            <form
              className="  mt-3 max-w-xl"
              id="new-api-key"
              onSubmit={formik.handleSubmit}
            >
              <div className="mb-6">
                <label
                  className="block text-gray-200 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  E-mail address
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring"
                  id="email"
                  name="email"
                  required
                  onChange={formik.handleChange}
                  placeholder="example@example.com"
                  type="text"
                  value={formik.values.email}
                />

                <div className="  mt-3 mb-6">
                  <label className="text-gray-500 font-bold">
                    <input
                      checked={formik.values.keepInformed}
                      className="mr-2 leading-tight form-checkbox text-gladior-yellow"
                      id="keepInformed"
                      name="keepInformed"
                      onChange={formik.handleChange}
                      type="checkbox"
                    />
                    <span className="text-sm">
                      I want to be kept informed by the latest developments at
                      Gladior.
                    </span>
                  </label>
                </div>
              </div>

              {contactSucces === true && (
                <div
                  className="bg-gray-100 border-l-4 border-gladior-pink text-gladior-pink p-4 mb-6"
                  role="alert"
                >
                  <p className="font-bold text-black">
                    Thanks for your interest!
                  </p>

                  <p className="text-black">
                    To access our Connector you need the code below.
                  </p>

                  <p className="text-black mt-10">
                    <code>{apiKey}</code>
                  </p>

                  <a href={connectorLink}>
                    LinkedIn Company Connector for Google Data Studio
                  </a>
                </div>
              )}

              {formik.errors.email ? (
                <div
                  className="bg-gray-100 border-l-4 border-gladior-pink text-gladior-pink p-4 mb-6"
                  role="alert"
                >
                  <p className="font-bold text-gladior-pink">Error</p>

                  <p className="text-gladior-pink">{formik.errors.email}</p>
                </div>
              ) : null}

              {contactFail === true && (
                <div
                  className="bg-gray-100 border-l-4 border-gladior-pink text-gladior-pink p-4 mb-6"
                  role="alert"
                >
                  <p className="font-bold text-gladior-pink">
                    Unknown error occurred
                  </p>

                  <p className="text-gladior-pink">
                    An unknown error has occurred.
                  </p>
                </div>
              )}

              <div className="flex items-center justify-between">
                <button
                  className="bg-gladior-pink hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring inline-flex items-center "
                  type="button"
                  type="submit"
                >
                  {formik.isSubmitting === true && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        className="opacity-25"
                        cx={12}
                        cy={12}
                        r={10}
                        stroke="currentColor"
                        strokeWidth={4}
                      />
                      {` `}
                      <path
                        className="opacity-75"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                  Generate API Key
                </button>
              </div>
            </form>

            <h3 className="mt-6 text-xl text-white font-bold mb-3 font-display ">
              Support
            </h3>

            <p className="text-base leading-6 text-gray-300 max-w-3xl">
              <a
                className="text-gladior-pink"
                href="/data-studio/linkedin-company-connector/support/"
              >
                Support page
              </a>
              {` `}
              <br />
              <a
                className="text-gladior-pink"
                href="https://github.com/Gladior-B-V/linkedin-company-connector/issues"
              >
                Report an issue
              </a>
              {` `}
              <br />
              <a className="text-gladior-pink" href="/voorwaarden/">
                Terms and conditions
              </a>
            </p>
          </section>
        </main>
      </Layout>
    </>
  );
}
export default LinkedInCompanyConnector;
